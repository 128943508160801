















import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'

export type BranchDataType = {
  id: number
  code: string
  name: string
}

export type EditRequestBodyType = {
  requestType: number
  academyId: number
  requests: { branchId: number; newBranchName: string }[]
}

@Component({
  components: {
    ModalBase,
    ButtonBase,
    InputWithLabel,
  },
})
export default class ModalAdminCustomerClassroomEdit extends Mixins(ModalBaseMethod) {
  @Prop()
  academy!: { id: number; name: string; code: string }

  private branchData: BranchDataType = { id: 0, code: '', name: '' }

  // 教室編集時のリクエストコード
  private readonly editRequestType = 6

  /**
   * ボタンの色（ボタン操作制御）
   */
  private get colortype(): string {
    const permitted = this.errors.length === 0

    return permitted ? 'blue' : 'pointer-events-none'
  }

  /**
   * 教室情報を引数にとり、モーダル表示
   */
  public showWithBranchData(branchData: BranchDataType) {
    this.branchData = branchData
    this.show()
  }

  /**
   * 教室名変更申請
   */
  private async editRequest() {
    const body = this.parseEditRequestBody()
    await Vue.prototype.$http.httpWithToken
      .post('/requests', body)
      .then(() => {
        alert('教室名変更申請が完了しました。')
        this.hide()
        this.$emit('request-success', this.branchData.id, 'rename')
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('教室名が重複したものは登録できません。')
        }
      })
  }

  /**
   * 教室名変更申請ボディのパース処理
   */
  private parseEditRequestBody(): EditRequestBodyType {
    return {
      requestType: this.editRequestType,
      academyId: this.academy.id,
      requests: [{ branchId: this.branchData.id, newBranchName: this.branchData.name }],
    }
  }

  /**
   * 入力値のバリデーションエラー
   */
  private get errors(): string[] {
    const errors: string[] = []
    const newName = this.branchData.name
    if (newName === '') {
      errors.push('教室名を入力してください')
    }
    if (newName.length > 30) {
      errors.push('教室名は30文字以内で入力してください')
    }
    return errors
  }
}
