

















































import { Component, Mixins, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import { ServiceCodeEnum, ServiceCodeType } from '@/types/teacher/service'

export type BrancheType = {
  id: number
  code: string
  name: string
  services: ServiceType[]
}

export type ServiceType = {
  id: number
  name: string
  code: ServiceCodeType
  subjects: SubjectType[]
}

export type SubjectType = {
  branchServiceId: number
  subjectId: number
  name: string
  isEnabled: boolean
  isRequesting: boolean
}

export type ServiceParamType = {
  name: string
  ko: boolean
  su: boolean
  ei: boolean
  ri: boolean
  sh: boolean
}

@Component({
  components: {
    ModalBase,
  },
})
export default class ModalAdminCustomerService extends Mixins(ModalBaseMethod) {
  private serviceDatas: ServiceParamType[] = []

  private serviceFields: object[] = [
    { key: 'name', label: 'サービス名' },
    { key: 'ko', label: '国語' },
    { key: 'su', label: '算数/数学' },
    { key: 'ei', label: '英語' },
    { key: 'ri', label: '理科' },
    { key: 'sh', label: '社会' },
  ]

  private CALENDAR_SERVICE_ID = 4
  private onOffCalendar = false
  private onOffGpt = false

  public async showWithLoadService(academyId: number, branchId: number) {
    const params = [`academyId=${academyId}`, `branchId=${branchId}`]

    await Vue.prototype.$http.httpWithToken
      .get(`/v3/branches/services?${params.join('&')}`)
      .then((res: any) => {
        const branch: BrancheType = res.data.branches[0]
        this.serviceDatas = branch?.services
          .filter((service) => {
            if (ServiceCodeEnum.GPT_SERVICE_CODE === service.code) {
              this.onOffGpt = service.subjects[0].isEnabled || false
            }
            if (ServiceCodeEnum.CALENDAR_SERVICE_CODE === service.code) {
              this.onOffCalendar = service.subjects[0].isEnabled || false
            }
            return ![ServiceCodeEnum.GPT_SERVICE_CODE, ServiceCodeEnum.CALENDAR_SERVICE_CODE].includes(service.code)
          })
          .map((service) => {
            return this.serviceParams(service)
          })
        this.show()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
  }

  private calendarServiceId = 4

  private serviceParams(service: ServiceType): ServiceParamType {
    return {
      name: service.name,
      ko: this.subjectStatus(service.subjects, '国語'),
      su: this.subjectStatus(service.subjects, '算数/数学'),
      ei: this.subjectStatus(service.subjects, '英語'),
      ri: this.subjectStatus(service.subjects, '理科'),
      sh: this.subjectStatus(service.subjects, '社会'),
    }
  }

  private subjectStatus(subjects: SubjectType[], subjectName: string): boolean {
    const subject = subjects.find((item) => {
      return item.name === subjectName
    })
    return subject?.isEnabled || false
  }
}
