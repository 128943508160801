





























import { Component, Mixins, Vue, Prop } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'

export type BranchDataType = {
  code: string
  name: string
}

export type AddRequestBodyType = {
  requestType: number
  academyId: number
  requests: { branchCode: string; branchName: string }[]
}

@Component({
  components: {
    ModalBase,
    ButtonBase,
    InputWithLabel,
  },
})
export default class ModalAdminCustomerClassroomAdd extends Mixins(ModalBaseMethod) {
  @Prop()
  academy!: { id: number; name: string; code: string }

  private branchDatas: BranchDataType[] = [{ code: '', name: '' }]

  private addRow(): void {
    if (!this.lessThanMaxRows) return

    this.branchDatas.push({ code: '', name: '' })
  }

  private get lessThanMaxRows(): boolean {
    return this.branchDatas.length < 5
  }

  private removeRow(index: number): void {
    this.branchDatas.splice(index, 1)
  }

  private resetBranchDatas(): void {
    this.branchDatas = [{ code: '', name: '' }]
  }

  /**
   * 入力値のバリデーションエラー
   */
  private get errors(): { code: { index: number; error: string }[]; name: { index: number; error: string }[] } {
    const errors = {
      code: [] as { index: number; error: string }[],
      name: [] as { index: number; error: string }[],
    }

    for (let i = 0; i < this.branchDatas.length; i++) {
      const branch = this.branchDatas[i]

      if (branch.code !== '') {
        if (!branch.code.match(/^[a-zA-Z0-9]+$/)) {
          errors.code.push({ index: i, error: '教室コードは半角英数字で入力してください' })
        }
        if (branch.code.length > 7) {
          errors.code.push({ index: i, error: '教室コードは7桁以内で入力してください' })
        }
      }

      if (branch.name !== '') {
        if (branch.name.length > 30) {
          errors.name.push({ index: i, error: '教室名は30文字以内で入力してください' })
        }
      }
    }

    return errors
  }

  private get codeErrors(): any {
    return (index: number) => {
      return this.errors.code.filter((err) => err.index === index).map((obj) => obj.error)
    }
  }

  private get nameErrors(): any {
    return (index: number) => {
      return this.errors.name.filter((err) => err.index === index).map((obj) => obj.error)
    }
  }

  /**
   * ボタンの色（ボタン操作制御）
   */
  private get colortype(): string {
    const permitted =
      this.branchDatas.length > 0 &&
      this.branchDatas.filter((branch) => branch.code === '' || branch.name === '').length === 0 &&
      this.errors.code.length === 0 &&
      this.errors.name.length === 0

    return permitted ? 'blue' : 'pointer-events-none'
  }

  // 教室追加時のリクエストコード
  private readonly addRequestType = 4

  /**
   * 教室追加申請
   */
  private async addRequest() {
    const body = this.parseAddRequestBody()
    await Vue.prototype.$http.httpWithToken
      .post('/requests', body)
      .then(() => {
        alert('教室追加を申請しました。承認されるまでしばらくお待ちください。')
        this.resetBranchDatas()
        this.hide()
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('教室コードもしくは教室名が重複したものが申請中か、既に登録されています。')
        }
      })
  }

  /**
   * 教室追加申請ボディのパース処理
   */
  private parseAddRequestBody(): AddRequestBodyType {
    const requests: { branchCode: string; branchName: string }[] = []
    this.branchDatas.forEach((data) => {
      if (data.code && data.name) {
        requests.push({ branchCode: `${this.academy.code}-${data.code}`, branchName: data.name })
      }
    })
    return {
      requestType: this.addRequestType,
      academyId: this.academy.id,
      requests: requests,
    }
  }
}
