










import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class FileDropArea extends Vue {
  // ファイルドラッグ中
  private isDropOver = false

  @Prop({ default: null })
  fileType?: string

  private input!: HTMLInputElement | null

  private onClick() {
    if (!this.input) {
      this.input = document.createElement('input') as HTMLInputElement
      this.input.type = 'file'
      this.input.onchange = this.changeFile
    }
    this.input.click()
  }

  private changeFile(e: Event) {
    this.isDropOver = false

    const files = (e.target as HTMLInputElement).files || []
    if (files.length == 0) return alert('ファイルの読み込みに失敗しました。')
    if (files.length > 1) return alert('複数ファイルの読み込みはできません。')

    const file = files[0]
    // ! TODO 以下のファイル形式判定ロジックがmac版Chromeでは機能するが、windows版Chromeでは機能しない為、一旦コメントアウト
    // ! 原因がわかり次第修正(それまではサーバ側で弾くようにする)
    // if (this.fileType && file.type.indexOf(this.fileType) == -1) {
    //   return alert(`${this.fileType}形式以外は読み込みできません。`)
    // }

    this.$emit('drop', file)
    this.input?.remove()
  }
  /**
   * ファイルドラッグイベント
   * type == 'over'の場合、レイアウト制御
   */
  private onDrag(type: string) {
    this.isDropOver = type == 'over'
  }

  /**
   * ファイルドロップイベント
   * csvファイルアップロード時、CSV取り込み
   */
  private onDropFile(event: DragEvent) {
    this.isDropOver = false

    const files = event.dataTransfer?.files || []
    if (files.length == 0) return alert('ファイルの読み込みに失敗しました。')
    if (files.length > 1) return alert('複数ファイルの読み込みはできません。')

    const file = files[0]
    // ! TODO 以下のファイル形式判定ロジックがmac版Chromeでは機能するが、windows版Chromeでは機能しない為、一旦コメントアウト
    // ! 原因がわかり次第修正(それまではサーバ側で弾くようにする)
    // if (this.fileType && file.type.indexOf(this.fileType) == -1) {
    //   return alert(`${this.fileType}形式以外は読み込みできません。`)
    // }

    this.$emit('drop', file)
  }
}
