










import { Component, Mixins } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

export type CsvLoadError = { row: number; message: string }

@Component({
  components: {
    ModalBase,
    ButtonBase,
  },
})
export default class ModalCsvLoadErrors extends Mixins(ModalBaseMethod) {
  private errors: CsvLoadError[] = []

  public showErrors(errors: CsvLoadError[]) {
    this.errors = errors
    this.show()
  }
}
